import { StyleSheet } from "@react-pdf/renderer";
import "../fontRegister"

export const styles = StyleSheet.create({
  dateSigned2: {
    paddingRight: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  signature: {
    paddingRight: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  summaryDisclaim: {
    marginTop: 10,
    borderTop: "1px solid black",
    width: "250%",
    position: "relative",
  },
  ds: {
    color: "white",
    fontSize: 5,
    position: "absolute",
    bottom: 20,
  },
  column: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  innerColumn1: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    paddingRight: 7,
  },
  innerColumn2: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    marginTop: 10,
  },
  logo: {
    height: 50,
    width: 170,
    padding: 5,
  },
  smallLogo: {
    height: 50,
    width: 140,
    padding: 5,
  },
  dealerLogo: {
    height: 100,
    width: 170,
    padding: 5,
  },
  text: {
    fontSize: 8,
    lineHeight: 1.8,
    fontFamily: "Roboto",
  },
  disclosures: {
    fontSize: 8,
    lineHeight: 3,
    fontFamily: "Roboto",
  },
  disclosuresBold: {
    fontSize: 8,
    fontWeight: "bold",
    lineHeight: 3,
    fontFamily: "Roboto-Bold",
  },
  textBold: {
    fontSize: 8,
    lineHeight: 1.8,
    fontWeight: "bold",
    fontFamily: "Roboto-Bold",
  },
  details: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  innerRow1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "flex-start",
  },
  colWithMargin: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  rowStart: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  address: {
    fontSize: 6,
    lineHeight: 1.2,
  },
  company: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: 10,
    marginLeft: 20,
  },
  dealerInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginRight: 20,
  },
  dealer: {
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 20,
    fontFamily: "Roboto-Bold",
  },
  quoteNumber: {
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Roboto-Bold",
  },
  pageAndDate: {
    fontSize: 8,
    alignSelf: "flex-start",
    fontWeight: "bold",
    fontFamily: "Roboto-Bold",
    marginTop: 5,
  },
  pageAndDate2: {
    fontSize: 8,
    fontWeight: "bold",
    fontFamily: "Roboto-Bold",
  },
  pd3Bold: {
    fontSize: 8,
    fontWeight: "bold",
    fontFamily: "Roboto-Bold",
    bottom: -60,
  },
  pd3: {
    fontSize: 8,
    fontFamily: "Roboto",
    bottom: -60,
  },
  bigBold: {
    fontSize: 14,
    fontWeight: "bold",
    padding: 5,
    fontFamily: "Roboto-Bold",
  },
  page1TextLeft: {
    fontSize: 12,
    lineHeight: 1.2,
    fontWeight: "bold",
    fontFamily: "Roboto-Bold",
  },
  page1TextRight: {
    fontSize: 12,
    lineHeight: 1.2,
    fontFamily: "Roboto",
  },
  customer: {
    fontSize: 14,
    color: "white",
    fontWeight: "bold",
    fontFamily: "Roboto-Bold",
    padding: 5,
  },
  customerQDiv: {
    backgroundColor: "black",
    borderRadius: 5,
    marginBottom: 20,
  },
  disclosuresHeading: {
    fontFamily: "Roboto-Bold",
    fontWeight: "bold",
    textDecoration: "underline",
    fontSize: 16,
  },
  rowCenterPad: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  colStartPad: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 20,
    paddingLeft: 40,
    paddingRight: 40,
  },
  rowCenterMargin: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: 20,
  },
  colStartMargin: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 40,
  },
  colStartMargin2: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 40,
    marginTop: 10,
  },
  rowStartMargin: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: 15,
    marginLeft: 40,
  },
  colCentermargin: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginLeft: 20,
  },
  colCenterPad: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingRight: 40,
  },
  fixedSign: {
    position: "absolute",
    left: 480,
    bottom: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: 200,
  },
  dateSigned: {
    color: "white",
    fontSize: 8,
    position: "absolute",
    bottom: -25,
  },
  colCenterPadRight: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingRight: 30,
  },
  infoBox: {
    border: "1px solid grey",
    alignSelf: "stretch",
    marginRight: 40,
  },
  unitDetailsContain: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: 10,
    marginBottom: 10,
    paddingBottom: 20,
    flexDirection: "row",
    alignItems: "center",
    // marginRight: 40,
    width: '100%'
  },
  customerSignStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    left: "30%",
    bottom: 10,
    width: 300,
  },
  customerSignStyle2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    left: "5%",
    bottom: 10,
  },
  customerSignStyle3: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    left: "30%",
    bottom: 10,
    width: 250,
  },
  dealerSignStyle: {
    position: "absolute",
    left: "50%",
    bottom: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: 200
  },
  dealerSignStyle2: {
    position: "absolute",
    right: "5%",
    bottom: 40,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: 200,
  },
  dateSign: {
    color: "white",
    fontSize: 8,
    position: "absolute",
    bottom: -25,
  },
  dateSign2: {
    color: "white",
    fontSize: 8,
    position: "absolute",
    bottom: -20,
  },
  timeDate: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 5,
  }
});
