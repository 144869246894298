import { useEffect, useRef, cloneElement, Children, useState } from "react";
import { createPortal } from "react-dom";

function Portal({ children, handleClose }) {
  // Use useRef to persist the container and external window references
  const containerEl = useRef(document.createElement("div"));
  const [externalWindow, setExternalWindow] = useState<Window | null>(null);

  useEffect(() => {
    // Create and open the new window
    const newWindow: Window | null = window.open(
      "",
      "",
      "width=600,height=500,left=200,top=200"
    );
    
    newWindow?.document.body.appendChild(containerEl.current);
    setExternalWindow(newWindow); // Update the state with the new window

    newWindow?.addEventListener("beforeunload", (ev) => {
      handleClose();
    });

    // Cleanup function
    return () => {
      newWindow?.close();
    };
  }, []);

  // Prepare children with the externalWindow prop only if externalWindow is available
  const childrenWithProps = externalWindow
    ? Children.map(children, (child) =>
        cloneElement(child, { containerWindow: externalWindow })
      )
    : null;

  // Use ReactDOM.createPortal to render the cloned children into the container element
  return externalWindow
    ? createPortal(childrenWithProps, containerEl.current)
    : null;
}

export default Portal;
