import React, { useEffect, memo, FC } from "react";
import { Alert, Typography } from "@mui/material";
import Choices from "./Choices";
import ReusableAccordian from "./ReusableAccordion";
import usePersistedState from "../hooks/usePersistedState";
import { useStore, useAccordion } from "../store/store";
import { useRequiredMsgs } from "../store/requiredMsgs";
import { Image } from "cloudinary-react";

import ScreenPocketAccordian from "./ScreenPocketAccordian";
import {
  screenLayoutImages,
  screenColors,
  singleScreenLayout,
  addScreen,
  addDoubleScreen,
  addPairSwing,
  layoutLeft,
  layoutRight,
} from "../initialValues/screens";
import { RowLayout, ColLayout } from "../util/accordianStyles";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material";

const ColLayoutLeftDivider = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  borderLeft: `2px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 2),
}));

const ColLayoutTopDivider = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  borderTop: `2px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 2),
}));

const RowLayoutSpacing = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  padding: theme.spacing(1, 2),
}));

const odds = ["3L OR 3R", "5L OR 5R", "7L OR 7R"];

interface ScreenProps {
  doorType: string
  isWeirdSixteenRange: boolean
  fivePanel16: boolean
  isPocketAllowed: boolean
  editQuote: boolean
  submitPressed: boolean
  widerThan16: boolean
}

const ScreenAccordian: FC<ScreenProps> = ({
  doorType,
  isWeirdSixteenRange,
  fivePanel16,
  isPocketAllowed,
  editQuote,
  submitPressed,
  widerThan16,
}) => {
  const theme = useTheme();
  const addToQuote = useStore((state) => state.addToQuote);

  const screen = useStore((state) => state.quote.addScreen);
  const screenVal = screen && screen.replace(" (upcharge applies)", "");

  const screenLayout = useStore((state) => state.quote.screenLayout);
  const screenColorChoice = useStore((state) => state.quote.screenColor);
  const cornerType = useStore((state) => state.quote.cornerType);

  const isDoubleSelected = screenVal && screenVal.includes("Double");

  const swingType = useStore((state) => state.quote.swingType);
  const isSwingPair = swingType === "Pair Swing";

  const stiles = useStore((state) => state.quote.stilesAndRails);
  const isSlimline =
    stiles && (stiles.includes("Slimline") || stiles.includes("Lock"));

  const bifoldConfiguration = useStore((state) => state.quote.configuration);
  const exactConfig = useStore((state) => state.quote.exactConfig);

  const handleErrMsgChange = useStore((state) => state.handleError);

  const oneSideOddConfig =
    doorType.includes("Bi-fold") && odds.includes(bifoldConfiguration || "");

  const oneDirectionConfig =
    doorType === "Swing" &&
    swingType === "Single Swing" &&
    exactConfig.slice(-2) === "LH"
      ? "sL"
      : doorType === "Swing" &&
        swingType === "Single Swing" &&
        exactConfig.slice(-2) === "RH"
      ? "sR"
      : doorType.includes("Bi-fold") && exactConfig.length === 2
      ? exactConfig
      : "";

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[25].expanded);
  const require = useAccordion((state) => state.expanded[25].require);

  const [displayedImage, setDisplayedImage] = usePersistedState(
    "displayedScreenImage",
    "",
    submitPressed,
    editQuote
  );

  const [displayedPocketImg, setDisplayedPocketImg] = usePersistedState(
    "displayedPocketImg",
    "",
    submitPressed,
    editQuote
  );

  const isCornerUnit = doorType?.includes("Corner");

  useEffect(() => {
    if (isDoubleSelected && oneSideOddConfig && oneDirectionConfig)
      defaultPocketWide();
    if (!isDoubleSelected && oneDirectionConfig && oneDirectionConfig[1])
      defaultLayout();
  }, [isDoubleSelected, oneSideOddConfig, oneDirectionConfig]);

  useEffect(() => {
    if (!isCornerUnit || !cornerType) return;
    if (widerThan16 && screenVal) {
      return addToQuote({
        addScreen: "",
        screenPocket: "",
      });
    }

    if (screenVal) {
      const img =
        cornerType === "Inside"
          ? "Double Screen Inside Corner"
          : "Double Screen Outside Corner";
      setDisplayedImage(img);
    }
  }, [cornerType, widerThan16]);

  const defaultLayout = () => {
    if (!oneDirectionConfig || !oneDirectionConfig[1]) return;
    if (oneDirectionConfig[1] === "L") {
      setDisplayedImage("Single Screen Compress Left");
      setDisplayedPocketImg("Single Screen Compress Left");
      addToQuote({ screenLayout: "Single Compress Left" });
    }
    if (oneDirectionConfig[1] === "R") {
      setDisplayedImage("Single Screen Compress Right");
      setDisplayedPocketImg("Single Screen Compress Right");
      addToQuote({ screenLayout: "Single Compress Right" });
    }
  };

  const defaultPocketWide = () => {
    if (!isDoubleSelected) return;
    const pocketSide =
      oneDirectionConfig[1] === "L"
        ? "Add Pocket Right"
        : oneDirectionConfig[1] === "R"
        ? "Add Pocket Left"
        : "Add Pocket";

    setDisplayedPocketImg(pocketSide);
    addToQuote({ screenPocket: pocketSide });
  };

  const handleAddScreenClick = (label: string, swingPair?: boolean) => {
    const checked = label === screenVal;

    handleErrMsgChange("jambErr", "");

    if (checked) {
      addToQuote(
        {
          addScreen: "",
          jamb: "",
        },
        "addScreen"
      );

      if (label === "Add Double Screen" && swingPair) {
        setDisplayedImage("");
      }
      widerThan16 && setDisplayedImage("");
    } else {
      addToQuote(
        {
          addScreen: label,
          jamb: "",
        },
        "addScreen"
      );

      if (isCornerUnit) {
        const img =
          cornerType === "Inside"
            ? "Double Screen Inside Corner"
            : "Double Screen Outside Corner";
        setDisplayedImage(img);
        setDisplayedPocketImg("");
      } else if (widerThan16) {
        setDisplayedImage("Double Screen");
      }

      if (label === "Add Double Screen" && swingPair) {
        setDisplayedImage("Double Screen");
      }
    }
  };

  const handlePocketLayout = (layout: string, clickNumber: string, isPocket: boolean) => {
    //passsed to screen layout and screen pocket choices
    if (clickNumber !== "secondClick" && !isPocket) {
      setDisplayedImage(layout);
    }

    if (clickNumber === "secondClick") {
      !isPocket && setDisplayedImage("");
      return setDisplayedPocketImg("");
    }

    layout === "Double Screen"
      ? setDisplayedPocketImg("")
      : setDisplayedPocketImg(layout);
  };

  const handleLayoutClick = (label: string) => {
    const checked = label === screenLayout;

    if (checked) {
      addToQuote({
        screenLayout: "",
      });
      handlePocketLayout(label, "secondClick", false);
      setDisplayedImage("");
    } else {
      addToQuote({
        screenLayout: label,
      });

      if (findRequired("Screen Layout")) removeRequired("Screen Layout");
      handlePocketLayout(label, "firstClick", false);
      setDisplayedImage(label);
    }
  };

  const handleScreenColorClick = (label: string) => {
    const checked = label === screenColorChoice;
    const requiredKey = "Screen Extrusion Color";

    if (checked) {
      addToQuote({ screenColor: "" });
    } else {
      addToQuote({ screenColor: label });

      if (findRequired(requiredKey)) removeRequired(requiredKey);
    }
  };

  return (
    <>
      <ReusableAccordian
        expanded={expanded}
        handleAccordClick={handleAccordClick}
        accordionName="screen"
        heading="Brio Retractable Screen"
        secondaryHeading="Select Screen Option"
        required={require ? true : false}
      >
        {isCornerUnit && widerThan16 ? (
          <Alert severity="error">
            Screens not available if width is greater than 192 inches
          </Alert>
        ) : (
          <ColLayout>
            {(isSlimline && isPocketAllowed) && (
              <Typography variant="subtitle1">
                *Pocketing Screen recommended for Slimline
              </Typography>
            )}

            <Typography variant="subtitle1">
              *upcharge applies for all screens
            </Typography>

            {!screenVal ? (
              <>
                {(doorType === "Swing" && isSwingPair) ||
                ((doorType === "Bi-fold" || doorType === "Bi-fold Window") &&
                  exactConfig.includes("L") &&
                  exactConfig.includes("R") &&
                  !isWeirdSixteenRange &&
                  !widerThan16) ? (
                  <RowLayout>
                    {addPairSwing.map((state) => (
                      <div
                        key={state.id}
                        onClick={() => handleAddScreenClick(state.label, true)}
                      >
                        <Choices
                          label={state.label}
                          variant={
                            state.label === screenVal ? "filled" : "outlined"
                          }
                          checkMark={state.label === screenVal}
                          wide={true}
                        />
                      </div>
                    ))}
                  </RowLayout>
                ) : (isCornerUnit && !widerThan16) ||
                  (!isWeirdSixteenRange && widerThan16) ||
                  (isWeirdSixteenRange && !widerThan16 && !fivePanel16) ? (
                  <RowLayout>
                    {addDoubleScreen.map((state) => (
                      <div
                        key={state.id}
                        onClick={() => handleAddScreenClick(state.label)}
                      >
                        <Choices
                          label={state.label}
                          variant={
                            state.label === screenVal ? "filled" : "outlined"
                          }
                          checkMark={state.label === screenVal}
                          wide={true}
                        />
                      </div>
                    ))}
                  </RowLayout>
                ) : !isCornerUnit &&
                  ((!isWeirdSixteenRange && !widerThan16 && !isSwingPair) ||
                    (isWeirdSixteenRange && !widerThan16 && fivePanel16)) ? (
                  <RowLayout>
                    {addScreen.map((state) => (
                      <div
                        key={state.id}
                        onClick={() => handleAddScreenClick(state.label)}
                      >
                        <Choices
                          label={state.label}
                          variant={
                            state.label === screenVal ? "filled" : "outlined"
                          }
                          checkMark={state.label === screenVal}
                          wide={true}
                        />
                      </div>
                    ))}
                  </RowLayout>
                ) : null}
              </>
            ) : (
              <>
                {(doorType === "Swing" && isSwingPair) ||
                ((doorType === "Bi-fold" || doorType === "Bi-fold Window") &&
                  exactConfig.includes("L") &&
                  exactConfig.includes("R") &&
                  !isWeirdSixteenRange &&
                  !widerThan16) ? (
                  <>
                    <RowLayout>
                      {addPairSwing.map((state) => (
                        <div
                          key={state.id}
                          onClick={() =>
                            handleAddScreenClick(state.label, true)
                          }
                        >
                          <Choices
                            label={state.label}
                            variant={
                              state.label === screenVal ? "filled" : "outlined"
                            }
                            checkMark={state.label === screenVal}
                            wide={true}
                          />
                        </div>
                      ))}
                    </RowLayout>

                    <RowLayout>
                      <ColLayout
                        style={{ position: "relative", paddingTop: 25 }}
                      >
                        {screenVal === "Add Screen" && (
                          <>
                            <Typography
                              style={{ position: "absolute", top: 15 }}
                              variant="subtitle1"
                            >
                              Screen Layout
                              <span style={{ color: "red", fontSize: 22 }}>
                                *
                              </span>
                            </Typography>

                            <RowLayoutSpacing>
                              {singleScreenLayout.map((state) => (
                                <div
                                  key={state.id}
                                  onClick={() => handleLayoutClick(state.label)}
                                >
                                  <Choices
                                    label={state.label}
                                    variant={
                                      state.label === screenLayout ||
                                      state.pdfLabel === screenLayout
                                        ? "filled"
                                        : "outlined"
                                    }
                                    checkMark={
                                      state.label === screenLayout ||
                                      state.pdfLabel === screenLayout
                                    }
                                  />
                                </div>
                              ))}
                            </RowLayoutSpacing>
                          </>
                        )}
                      </ColLayout>
                      <div
                        style={
                          screenVal === "Add Screen"
                            ? {
                                marginBottom: 10,
                                marginTop: 20,
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column",
                                padding: theme.spacing(1, 2),
                              }
                            : {
                                marginBottom: 10,
                                marginTop: 20,
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                              }
                        }
                      >
                        {screenVal === "Add Screen" && (
                          <Typography variant="subtitle1">
                            Screen Extrusion Color
                            <span style={{ color: "red", fontSize: 22 }}>
                              *
                            </span>
                          </Typography>
                        )}

                        <RowLayout>
                          {screenColors.map((state) => (
                            <div
                              key={state.id}
                              onClick={() =>
                                handleScreenColorClick(state.label)
                              }
                            >
                              <Choices
                                label={state.label}
                                variant={
                                  state.label === screenColorChoice
                                    ? "filled"
                                    : "outlined"
                                }
                                checkMark={state.label === screenColorChoice}
                              />
                            </div>
                          ))}
                        </RowLayout>
                      </div>
                    </RowLayout>
                  </>
                ) : (isCornerUnit && !widerThan16) ||
                  (isWeirdSixteenRange && !widerThan16 && !fivePanel16) ||
                  (!isWeirdSixteenRange && widerThan16) ? (
                  <RowLayout>
                    <ColLayout>
                      <RowLayout>
                        {addDoubleScreen.map((state) => (
                          <div
                            key={state.id}
                            onClick={() => handleAddScreenClick(state.label)}
                          >
                            <Choices
                              label={state.label}
                              variant={
                                state.label === screenVal
                                  ? "filled"
                                  : "outlined"
                              }
                              checkMark={state.label === screenVal}
                            />
                          </div>
                        ))}
                      </RowLayout>
                      <ColLayoutTopDivider>
                        <RowLayout>
                          {screenColors.map((state) => (
                            <div
                              key={state.id}
                              onClick={() =>
                                handleScreenColorClick(state.label)
                              }
                            >
                              <Choices
                                label={state.label}
                                variant={
                                  state.label === screenColorChoice
                                    ? "filled"
                                    : "outlined"
                                }
                                checkMark={state.label === screenColorChoice}
                              />
                            </div>
                          ))}
                        </RowLayout>
                      </ColLayoutTopDivider>
                    </ColLayout>
                  </RowLayout>
                ) : !isCornerUnit &&
                  ((!isWeirdSixteenRange && !widerThan16 && !isSwingPair) ||
                    (isWeirdSixteenRange && !widerThan16 && fivePanel16)) ? (
                  <>
                    <RowLayout>
                      {addScreen.map((state) => (
                        <div
                          key={state.id}
                          onClick={() => handleAddScreenClick(state.label)}
                        >
                          <Choices
                            label={state.label}
                            variant={
                              state.label === screenVal ? "filled" : "outlined"
                            }
                            checkMark={state.label === screenVal}
                          />
                        </div>
                      ))}
                    </RowLayout>

                    <RowLayout>
                      <ColLayout
                        style={{ position: "relative", paddingTop: 25 }}
                      >
                        <Typography
                          style={{ position: "absolute", top: 15 }}
                          variant="subtitle1"
                        >
                          Screen Layout
                          <span style={{ color: "red", fontSize: 22 }}>*</span>
                        </Typography>

                        <RowLayoutSpacing style={{ paddingTop: 10 }}>
                          {!oneDirectionConfig &&
                            singleScreenLayout.map((state) => (
                              <div
                                key={state.id}
                                onClick={() => handleLayoutClick(state.label)}
                              >
                                <Choices
                                  label={state.label}
                                  variant={
                                    state.label === screenLayout ||
                                    state.pdfLabel === screenLayout
                                      ? "filled"
                                      : "outlined"
                                  }
                                  checkMark={
                                    state.label === screenLayout ||
                                    state.pdfLabel === screenLayout
                                  }
                                />
                              </div>
                            ))}

                          {oneDirectionConfig &&
                            oneDirectionConfig[1] === "L" &&
                            layoutLeft.map((state) => (
                              <div key={state.id}>
                                <Choices
                                  label={state.label}
                                  variant="filled"
                                  checkMark={true}
                                />
                              </div>
                            ))}

                          {oneDirectionConfig &&
                            oneDirectionConfig[1] === "R" &&
                            layoutRight.map((state) => (
                              <div key={state.id}>
                                <Choices
                                  label={state.label}
                                  variant="filled"
                                  checkMark={true}
                                />
                              </div>
                            ))}
                        </RowLayoutSpacing>
                      </ColLayout>
                      <ColLayoutLeftDivider>
                        <Typography variant="subtitle1">
                          Screen Extrusion Color
                          <span style={{ color: "red", fontSize: 22 }}>*</span>
                        </Typography>

                        <RowLayout>
                          {screenColors.map((state) => (
                            <div
                              key={state.id}
                              onClick={() =>
                                handleScreenColorClick(state.label)
                              }
                            >
                              <Choices
                                label={state.label}
                                variant={
                                  state.label === screenColorChoice
                                    ? "filled"
                                    : "outlined"
                                }
                                checkMark={state.label === screenColorChoice}
                              />
                            </div>
                          ))}
                        </RowLayout>
                      </ColLayoutLeftDivider>
                    </RowLayout>
                  </>
                ) : null}

                {displayedImage &&
                  screenLayoutImages
                    .filter((image) => image.label === displayedImage)
                    .map((imageShown) => (
                      <RowLayout key={imageShown.label}>
                        <ColLayout
                          style={{
                            marginRight: 40,
                            marginTop: 10,
                            marginBottom: 5,
                          }}
                        >
                          <Image
                            cloudName="ag-millworks"
                            secure="true"
                            publicId={imageShown.img}
                          />
                          <Typography
                            style={{ alignSelf: "center" }}
                            variant="caption"
                          >
                            *As viewed from exterior
                          </Typography>
                        </ColLayout>
                      </RowLayout>
                    ))}
              </>
            )}
          </ColLayout>
        )}
      </ReusableAccordian>

      {screenVal && isPocketAllowed && (
        <ScreenPocketAccordian
          isDoubleSelected={isDoubleSelected || false}
          oneSideOddConfig={oneSideOddConfig}
          handlePocketLayout={handlePocketLayout}
          defaultPocketWide={defaultPocketWide}
          displayedPocketImg={displayedPocketImg}
          doorType={doorType}
        />
      )}
    </>
  );
}

export default memo(ScreenAccordian);
