import React, { useState, FC } from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import getModalStyle from "../util/getModalStyle";
import { styled } from "@mui/material/styles";

const StyledDivModal = styled("div")(({ theme }) => ({
  position: "absolute",
  width: 400,
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #000",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  textAlign: "center",
}));

const StyledDivFlexCenter = styled("div")({
  display: "flex",
  justifyContent: "center",
  paddingLeft: 20,
});

const StyledDivColFlexStart = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "flex-start",
});

const StyledTypography = styled(Typography)({
  color: "red",
  fontWeight: "bold",
  padding: 5,
});

interface ResetWarningModalProps {
  open: boolean;
  handleClose: () => void;
  modalText: string[];
}

const ResetWarningModal: FC<ResetWarningModalProps> = ({
  open,
  handleClose,
  modalText,
}) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const body = (
    <StyledDivModal style={modalStyle}>
      <h3>The Following Selections have been Reset:</h3>

      <StyledDivFlexCenter>
        <StyledDivColFlexStart>
          {modalText && modalText.length
            ? modalText.map((text) => (
                <StyledTypography key={text}>- {text}</StyledTypography>
              ))
            : null}
        </StyledDivColFlexStart>
      </StyledDivFlexCenter>

      <Button
        size="large"
        onClick={handleClose}
        color="primary"
        variant="contained"
        style={{ marginTop: 20 }}
      >
        Ok
      </Button>
    </StyledDivModal>
  );

  return (
    <Modal style={{zIndex: 50000}} open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};

export default ResetWarningModal;
