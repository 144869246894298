function getCornerImgWidth(height: number, config: string){
    if(!height || !config) return;

    const numPanels = Number(config.replace("L", "").replace("R", ""));
    var width: number = 0;

    switch(numPanels){
        case 1:
            width = Math.round(height/2.13)
            break;
        case 2:
            width = Math.round(height/1.34)
            break;
        case 3:
            width = Math.round(height/.98)
            break;
        case 4:
            width = Math.round(height/.74)
            break;
        case 5:
            width = Math.round(height/.60)
            break;
        case 6:
            width = Math.round(height/.51)
            break;
        case 7:
            width = Math.round(height/.48)
            break;
        case 8:
            width = Math.round(height/.43)
            break;
        default:
            break;
    }

    return width;
}

export default getCornerImgWidth