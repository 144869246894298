function resetModalTextHandler(
  prevQuote: any,
  newQuote: any,
  ignoreKey?: string
): string[] {
  var modalText: string[] = [];

  const resets = {
    woodSpecies: "Wood Species",
    swingType: "Swing Type",
    unitSize: "Unit Size",
    swingPanelWidth: "Panel Width",
    pivotHardware: "Pivot Hardware",
    pivotAction: "Pivot Movement",
    stilesAndRails: "Stiles",
    handleStyle: "Handle",
    handleColor: "Handle Finish",
    addDL: "Divided Lites",
    addSlidingScreen: "Screen",
    addScreen: "Screen",
    pivotPoint: "Pivot Point",
    dropseal: "Drop Seal",
    pivotMagnet: "Ghostop Magnet",
    paintAndPrime: "Paint/Stain",
    stain: "Paint/Stain",
    prime: "Paint/Stain",
    customPaintOrStain: "Paint/Stain",
    jamb: "Jamb",
  };

  for (const key in newQuote) {
    if (prevQuote[key] && !newQuote[key]) {
      if (key === ignoreKey) continue;
      if (key in resets) {
        modalText.push(resets[key]);
      }
    }
  }

  return modalText;
}

export default resetModalTextHandler;
