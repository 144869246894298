import React, { FC } from "react";
import ReusableAccordian from "../ReusableAccordion";
import fractionToInt from "../../util/fractionToInt";
import SizeLayout from "../SizeLayout";
import { useStore, useAccordion } from "../../store/store";
import { Typography, Paper, SelectChangeEvent } from "@mui/material";
import { Image } from "cloudinary-react";
import { RowLayout, ColLayout } from "../../util/accordianStyles";
import { styled } from "@mui/material/styles";

const StyledTypography = styled(Typography)({
  fontSize: 20,
  fontWeight: "bold",
  paddingRight: 40,
});

const StyledCloudinaryImage = styled(Image)({
  width: 400,
  height: "auto",
  objectFit: "contain",
});

interface UpdateObj {
  jamb: string
  addScreen: string
  screenPocket: string
  screenColor: string
  unitSize?: string
  cornerWidthRight?: string
}

const CornerUnitSizeAccordian: FC = () => {
  const addToQuote = useStore((state) => state.addToQuote);

  const cornerType = useStore((state) => state.quote.cornerType);
  const leftWidth = useStore((state) => state.quote.unitSize);
  const leftFraction = useStore((state) => state.quote.unitSizeFraction);

  const rightWidth = useStore((state) => state.quote.cornerWidthRight);
  const rightFraction = useStore(
    (state) => state.quote.cornerWidthFractionRight
  );

  const handleError = useStore((state) => state.handleError);

  const widthErr = useStore((state) => state.widthErr);
  const widthErrCornerRight = useStore((state) => state.widthErrCornerRight);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[2].expanded);

  const showError = (overall: number, key: string) => {
    let err = "";

    const max = 286.625;
    const min = 34.375;

    const errMsgHigh = "Width can not exceed";
    const errMsgLow = "Width must exceed";

    if (overall > max) {
      err = `${errMsgHigh} 286 5/8"`;
    } else if (overall < min) {
      err = `${errMsgLow} 34 3/8"`;
    }

    return handleError(key, err);
  };

  const handleWidthChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const num = parseInt(value);

    let update: UpdateObj = {
      jamb: "",
      addScreen: "",
      screenPocket: "",
      screenColor: "",
    };

    if (!num) update[key as keyof UpdateObj] = "";
    else update[key as keyof UpdateObj] = `${num}`;

    addToQuote(update);

    if (key.includes("unitSize") && num) {
      const overallLeft = fractionToInt(value, leftFraction);
      showError(overallLeft, "widthErr");
    } else if (key.includes("cornerWidth") && num) {
      const overallRight = fractionToInt(value, rightFraction);
      showError(overallRight, "widthErrCornerRight");
    }
  };

  const handleFractionChange = (key: string) => (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    if (value === null) return addToQuote({ [key]: "" });

    addToQuote({
      [key]: value,
      jamb: "",
      addScreen: "",
      screenPocket: "",
      screenColor: "",
    });

    if (key.includes("unitSize")) {
      const overallLeft = fractionToInt(leftWidth, value);
      showError(overallLeft, "widthErr");
    } else if (key.includes("cornerWidth")) {
      const overallRight = fractionToInt(rightWidth || "", value);
      showError(overallRight, "widthErrCornerRight");
    }
  };

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="unitSize"
      heading="Net Frame Width"
      secondaryHeading=" Select a Width"
      required={false}
      required5={true}
      colOrRow="col"
    >
      <Typography>
        Note: We recommend each Net Frame Width to be 1/2 inch less than the
        Rough Opening Width
      </Typography>

      <RowLayout>
        <ColLayout style={{ marginRight: 40 }}>
          <RowLayout>
            <StyledTypography>Left Net Width:</StyledTypography>
            <SizeLayout
              size={leftWidth}
              handleChange={handleWidthChange("unitSize")}
              sizeErr={widthErr}
              name="width"
              fractionValue={leftFraction || ""}
              handleFractionChange={handleFractionChange("unitSizeFraction")}
            />
          </RowLayout>
          <RowLayout>
            <StyledTypography>Right Net Width:</StyledTypography>
            <SizeLayout
              size={rightWidth || ""}
              handleChange={handleWidthChange("cornerWidthRight")}
              sizeErr={widthErrCornerRight}
              name="width"
              fractionValue={rightFraction || ""}
              handleFractionChange={handleFractionChange(
                "cornerWidthFractionRight"
              )}
            />
          </RowLayout>
        </ColLayout>

        {cornerType === "Outside" ? (
          <Paper elevation={3}>
            <StyledCloudinaryImage
              cloudName="ag-millworks"
              secure="true"
              publicId="v1691507923/Bifold_Corner_Configs/OUTSIDE_CORNER_DIM_v2"
            />
          </Paper>
        ) : cornerType === "Inside" ? (
          <Paper elevation={3}>
            <StyledCloudinaryImage
              cloudName="ag-millworks"
              secure="true"
              publicId="v1691507925/Bifold_Corner_Configs/INSIDE_CORNER_DIM_v2"
            />
          </Paper>
        ) : null}
      </RowLayout>
    </ReusableAccordian>
  );
}

export default CornerUnitSizeAccordian
