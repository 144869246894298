import React, { FC } from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { convertDecimalToFraction } from "../../util/pdfFormulas/convertDecimalToFraction";
import fractionToInt from "../../util/fractionToInt";
import noAccess from "../../util/noAccess";
import { UnitObj } from "../../util/quoteCartStorage";
import "../fontRegister"

const styles = StyleSheet.create({
  headerText: {
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Roboto-Bold",
  },
  text: {
    fontSize: 8,
    fontFamily: "Roboto",
  },
  textBold: {
    fontSize: 8,
    fontWeight: "bold",
    fontFamily: "Roboto-Bold",
  },
  textMd: {
    fontSize: 9,
    fontFamily: "Roboto",
  },
  textMdBold: {
    fontSize: 9,
    fontWeight: "bold",
    fontFamily: "Roboto-Bold",
  },
  textBorder: {
    fontSize: 8,
    fontFamily: "Roboto",
    borderBottom: "1px solid black",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  col: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    padding: 5,
  },
  contain: {
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingTop: 20,
  },
  rowCenter: {
    paddingLeft: 5,
    paddingRight: 5,
    border: "1px solid black",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  viewPad: {
    paddingLeft: 5,
    paddingRight: 5,
    border: "1px solid black",
  },
});

interface RailsObj {
  top: string
  bottom: string
  stile: string
  lock: string
  tbStile?: string
  fixedStile?: string
}

function getRailsHeader(unit: UnitObj, config: string, cornerSide?: string): RailsObj {
  let top = ""
  let bottom = ""
  let stile = ""
  let lock = ""

  const euro = "2 13/16";
  const slim = "1 7/8";
  const trad = "4 1/8";

  if (unit.doorType === "Pivot") {
    // const euroPivotLock = "2 15/16";
    // const tradPivotLock = "4 1/4";

    if (unit.stileCategory === "Traditional") {
      top = trad;
      bottom = unit.stilesAndRails === 'Bottom rail 4-3/4"' ? trad : "6 3/8";
      lock = trad;
      stile = trad;
    }else if (unit.pivotHardware === "Dorma") {
      if (unit.stileCategory?.includes("Slimline")) {
        top = slim;
        bottom = slim;
        lock = euro;
        stile = slim;
      } else if (unit.stileCategory === "Euro") {
        top = euro;
        bottom = euro;
        lock = euro;
        stile = euro;
      }
    } else {
      if (unit.pivotAction === "Free Swing") {
        if (unit.stileCategory?.includes("Slimline")) {
          top = euro;
          bottom = slim;
          lock = euro;
          stile = slim;
        } else if (unit.stileCategory === "Euro") {
          top = euro;
          bottom = euro;
          lock = euro;
          stile = euro;
        }
      } else {
        if (unit.stileCategory?.includes("Slimline")) {
          top = euro;
          bottom = trad;
          lock = euro;
          stile = slim;
        } else if (unit.stileCategory === "Euro") {
          top = euro;
          bottom = trad;
          lock = euro;
          stile = euro;
        }
      }
    }
  }else{
    if (unit.stileCategory === "Traditional") {
      top = trad;
      bottom = unit.stilesAndRails === 'Bottom rail 4-3/4"' ? trad : "6 3/8";
      lock = trad;
      stile = trad;
  
      return {
        top,
        bottom,
        stile,
        lock,
      };
    }
    
    if (unit.stileCategory === "Euro") {
      top = euro;
      // 09/16/24 change from: if inswing tbStile would be 2 15/16, now its always 2 13/16 per antonio
      lock = euro;
      bottom = unit.doorType === "Slider" && unit.sliderPanelSqft && unit.sliderPanelSqft > 50 ? trad : euro;
      stile = euro;
    } else if (unit.stileCategory?.includes("Slimline")) {
      if (
        unit.doorType.includes("Corner") &&
        cornerSide === "right" &&
        noAccess.includes(config) &&
        !noAccess.includes(unit.exactConfig)
      ) {
        top = slim;
        bottom = slim;
        stile = slim;
        lock = euro;
      } else if (
        unit.doorType.includes("Bi-fold") &&
        cornerSide === "left" &&
        noAccess.includes(config) &&
        !noAccess.includes(unit.cornerConfigRight || "")
      ) {
        top = slim;
        bottom = slim;
        stile = slim;
        lock = euro;
      } else if (
        unit.doorType.includes("Bi-fold") &&
        !noAccess.includes(config)
      ) {
        top = slim;
        bottom = slim;
        stile = slim;
        lock = euro;
      } else if (
        unit.doorType.includes("Bi-fold") &&
        noAccess.includes(config)
      ) {
        top = slim;
        bottom = slim;
        stile = slim;
        lock = slim;
      } else if (
        unit.doorType === "Slider" &&
        unit.sliderPocket === "No Pocket"
      ) {
        top = slim;
        bottom = euro;
        stile = slim;
        lock = euro;
      } else if (
        unit.doorType === "Slider" &&
        unit.sliderPocket === "With Pocket"
      ) {
        return {
          top: slim,
          bottom: euro,
          stile: slim,
          lock: euro,
          // stile2: euro,
        };
      } else if (unit.doorType === "Swing") {
        top = slim;
        bottom = slim;
        stile = slim;
        lock = euro;
      } else if (unit.doorType === "Fixed Panel") {
        top = slim;
        bottom = slim;
        stile = slim;
        lock = slim;
      }
    }
  }

    return {
      top,
      bottom,
      lock,
      stile,
    };
}

interface CutSheetHeaderProps {
  totalItems: number
  unitNumber: number
  salesOrder: string
  poNumber: string
  dealer: string
  unit: UnitObj
  woodSpecies: string
  cornerSide?: string
}

const CutSheetHeader: FC<CutSheetHeaderProps> = ({
  totalItems,
  unitNumber,
  salesOrder,
  poNumber,
  dealer,
  unit,
  woodSpecies,
  cornerSide,
}) => {
  const numPanels =
    unit.doorType.includes("Corner") && cornerSide === "left"
      ? unit.numberOfPanelsLeftCorner
      : unit.doorType.includes("Corner") && cornerSide === "right"
      ? unit.numberOfPanelsRightCorner
      : unit.numberOfPanels;

  const isOdd = Number(numPanels) % 2 === 1 ? true : false;

  const equalOrNonEqual = isOdd ? "Unequal" : "Equal";
  const slimline = unit.stilesAndRails?.includes("Traditional")
    ? `Slimline Curved (${equalOrNonEqual})`
    : `Slimline Square (${equalOrNonEqual})`;

  const inswingPair =
    unit.doorType === "Swing" &&
    unit.swingDirection === "Inswing" &&
    unit.swingType === "Pair Swing"
      ? true
      : false;

  const config =
    unit.doorType.includes("Corner") && cornerSide === "right"
      ? unit.cornerConfigRight
      : unit.exactConfig;

  //on 11/06/2023 changed euro stile sizes to be same for clad and wood
  const rails = getRailsHeader(unit, config || "", cornerSide);

  if (inswingPair && rails.lock) {
    rails["tbStile"] = rails.lock;
    rails.lock = convertDecimalToFraction(
      fractionToInt(rails.lock.split(" ")[0], rails.lock.split(" ")[1]) + 0.125
    );
  }

  if (
    unit.doorType === "Slider" &&
    unit.exactConfig.includes("O") &&
    unit.handleStyle
  ) {
    const stile = unit.stilesAndRails?.includes("Slimline")
      ? "2 3/8"
      : unit.stilesAndRails?.includes("Euro")
      ? "3 5/16"
      : "4 5/8";

    if (unit.handleStyle.includes("Truth")) {
      rails["fixedStile"] = stile;
    } else {
      const oneside = unit.exactConfig.includes("-")
        ? unit.exactConfig.split("-")[0]
        : unit.exactConfig;

      const numPanels = oneside.replace(/[^O^X^A]/g, "").length;
      if (numPanels >= 3) {
        rails["fixedStile"] = stile;
      }
    }
  }

  return (
    <View style={styles.contain}>
      <View style={[styles.row, { paddingBottom: 5 }]}>
        <Text
          style={styles.headerText}
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
        />
        <Text style={styles.headerText}>
          STILE:{" "}
          {unit.stilesAndRails?.includes("Slimline")
            ? slimline
            : unit.stileCategory}
        </Text>
        <Text style={styles.headerText}>
          ITEM #{unitNumber} OF {totalItems}
        </Text>
      </View>

      <View style={[styles.row, { borderBottom: "2px solid black" }]}>
        <Text style={styles.textMd}>
          <Text style={styles.textMdBold}>DEALER:</Text> {dealer}
        </Text>
        <Text style={styles.textMd}>
          <Text style={styles.textMdBold}>SO#:</Text> {salesOrder}
        </Text>
        <Text style={styles.textMd}>
          <Text style={styles.textMdBold}>PO#:</Text> {poNumber}
        </Text>
        <Text style={styles.textMd}>
          <Text style={styles.textMdBold}>DOOR:</Text> 2 1/4
        </Text>
        <Text style={styles.textMdBold}>THERMAL</Text>
      </View>

      <View style={[styles.row, { alignItems: "flex-start" }]}>
        <View style={styles.col}>
          <Text style={styles.textBorder}>TYPE</Text>
          <View>
            {unit.doorType === "Pivot" ?
            <View style={[styles.col, { border: "1px solid black" }]}>
              <Text style={styles.textBold}>{config}</Text>
              <Text style={styles.textBold}>{unit.pivotHardware}</Text>
              <Text style={styles.textBold}>{unit.pivotAction}</Text>
            </View>
            :
            <View style={styles.rowCenter}>
              <Text style={styles.textBold}>{config}</Text>
            </View>
            }
          </View>
        </View>
        {unit.doorType !== "Slider" ? (
          <View style={styles.col}>
            <Text style={styles.textBorder}>SWING</Text>
            <Text style={styles.text}>
              {unit.doorType === "Slider" || unit.doorType === "Fixed Panel"
                ? "N/A"
                : unit.doorType.includes("Bi-fold")
                ? "OS"
                : unit.swingDirection === "Outswing"
                ? "OS"
                : "IS"}
            </Text>
          </View>
        ) : (
          <Text />
        )}
        <View style={styles.col}>
          <Text style={styles.textBorder}>SILL</Text>
          <Text style={styles.text}>
            {unit.exactConfig === "Fixed Slider"
              ? "Raised SILLution"
              : unit.exactConfig === "Fixed Outswing" ||
                unit.exactConfig === "Fixed Inswing" ||
                unit.exactConfig === "Fixed Full Bound"
              ? "N/A"
              : unit.doorType === "Pivot"
              ? unit.sillStyle?.replace("Aluminum", "Alum")
              : unit.sillStyle}
          </Text>
        </View>
        <View style={styles.col}>
          <Text style={styles.textBorder}>SPECIES</Text>
          <Text style={styles.text}>{woodSpecies}</Text>
        </View>
        <View style={styles.col}>
          <Text style={styles.textBorder}>WIDTH</Text>
          <Text style={styles.text}>
            {unit.doorType.includes("Corner") && cornerSide === "right"
              ? `${unit.cornerWidthRight} ${
                  unit.cornerWidthFractionRight || ""
                }`
              : `${unit.unitSize} ${unit.unitSizeFraction || ""}`}
          </Text>
        </View>
        <View style={styles.col}>
          <Text style={styles.textBorder}>HEIGHT</Text>
          <Text style={styles.text}>{`${unit.height} ${
            unit.heightFraction || ""
          }`}</Text>
        </View>
        <View style={styles.col}>
          <Text style={styles.textBorder}>JAMB</Text>
          <Text style={styles.text}>
            {unit.jamb?.includes("Overall")
              ? unit.jamb
                  .replace("Standard", "")
                  .replace('"', "")
                  .replace("Overall Jamb Width", "")
                  .trim()
              : unit.jamb}
          </Text>
        </View>
        <View style={styles.col}>
          <Text style={styles.textBorder}># DOORS</Text>
          <Text style={styles.text}>{numPanels}</Text>
        </View>
        <View style={styles.col}>
          <Text style={styles.textBorder}>L.STILE</Text>
          <View>
            <View style={styles.viewPad}>
              <Text style={styles.textBold}>{rails?.lock}</Text>
            </View>
          </View>
        </View>
        <View style={styles.col}>
          <Text style={styles.textBorder}>STILE</Text>
          <View>
            <View style={styles.viewPad}>
              <Text style={styles.textBold}>{rails?.stile}</Text>
            </View>
          </View>
        </View>
        {inswingPair && rails?.tbStile ? (
          <View style={styles.col}>
            <Text style={styles.textBorder}>TB.STILE</Text>
            <Text style={styles.text}>{rails?.tbStile}</Text>
          </View>
        ) : (
          <Text />
        )}
        {rails?.fixedStile ? (
          <View style={styles.col}>
            <Text style={styles.textBorder}>F.STILE</Text>
            <Text style={styles.text}>{rails?.fixedStile}</Text>
          </View>
        ) : (
          <Text />
        )}
        <View style={styles.col}>
          <Text style={styles.textBorder}>T.RAIL</Text>
          <Text style={styles.text}>{rails?.top}</Text>
        </View>
        <View style={styles.col}>
          <Text style={styles.textBorder}>B.RAIL</Text>
          <Text style={styles.text}>{rails?.bottom}</Text>
        </View>
        <View style={styles.col}>
          <Text style={styles.textBorder}>QTY</Text>
          <Text style={styles.textBold}>{unit.quantity}</Text>
        </View>
      </View>
    </View>
  );
};

export default CutSheetHeader;
