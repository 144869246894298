import React, {
  useState,
  useEffect,
  FC,
  Dispatch,
  SetStateAction,
} from "react";
import { Typography, IconButton, Tooltip } from "@mui/material";
import CanvasBox from "./CanvasBox";
import { Resizable, NumberSize, ResizeDirection } from "re-resizable";
import Portal from "./Portal";
import { styled } from "@mui/material/styles";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloseIcon from "@mui/icons-material/Close";

const StyledDivRowSpace = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "move",
  backgroundColor: "#4b4b4b",
  padding: 5,
});

const StyledDivCol = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const StyledDivFixed = styled("div")({
  position: "fixed",
  display: "flex",
  flexDirection: "column",
  cursor: "pointer",
  zIndex: 1500,
});

interface PreviewProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  showPortal: boolean;
  setShowPortal: Dispatch<SetStateAction<boolean>>;
}

type RelState = {
  x: number;
  y: number;
};

interface PreviewState {
  x: number;
  y: number;
  rel: RelState | null;
  dragging: boolean;
}

const Preview: FC<PreviewProps> = ({
  open,
  setOpen,
  showPortal,
  setShowPortal,
}) => {
  const [size, setSize] = useState({ width: 500, height: 500 });

  const [preview, setPreview] = useState<PreviewState>({
    x: 100,
    y: 100,
    rel: null,
    dragging: false,
  });

  const enableDragging = (e: React.MouseEvent<any>) => {
    if (e.button !== 0) return;

    setPreview((prev) => ({
      ...prev,
      dragging: true,
      rel: {
        x: e.pageX - preview.x,
        y: e.pageY - preview.y,
      },
    }));

    e.stopPropagation();
    e.preventDefault();
  };

  const disableDragging = (e: React.MouseEvent<any>) => {
    setPreview((prev) => ({ ...prev, dragging: false }));
    e.stopPropagation();
    e.preventDefault();
  };

  const onMouseMove = (e: any) => {
    if (!preview.dragging) return;
    const dialogWidth = document.getElementById("node")?.clientWidth || 0;
    const dialogHeight = document.getElementById("node")?.clientHeight || 0;
    const x = Math.min(
      Math.max(0, e.pageX - (preview.rel?.x ? preview.rel.x : 0)),
      window.innerWidth - dialogWidth - 20
    );
    const y = Math.min(
      Math.max(0, e.pageY - (preview.rel?.y ? preview.rel.y : 0)),
      window.innerHeight - dialogHeight - 20
    );
    setPreview((prev) => ({
      ...prev,
      x: x,
      y: y,
    }));
    e.stopPropagation();
    e.preventDefault();
  };

  useEffect(() => {
    if (preview.dragging) {
      document.addEventListener("mousemove", onMouseMove);
    } else {
      document.removeEventListener("mousemove", onMouseMove);
    }

    return () => document.removeEventListener("mousemove", onMouseMove);
  }, [preview.dragging]);

  const handlePortalOpen = () => {
    setShowPortal(true);
    handleClose();
  };

  const handlePortalClose = () => {
    setShowPortal(false);
    setSize({ width: 500, height: 500 });
  };

  const handleClose = () => {
    setOpen(false);
    setSize({ width: 500, height: 500 });
  };

  const resizeStop = (
    e: any,
    direction: ResizeDirection,
    ref: HTMLElement,
    d: NumberSize
  ) => {
    setSize((prevSize) => {
      const newWidth = prevSize.width + d.width;
      const newHeight = prevSize.height + d.height;

      return {
        width: newWidth,
        height: newHeight,
      };
    });
  };

  return (
    <>
      {open && !showPortal ? (
        <StyledDivFixed
          id={"node"}
          style={{
            top: preview.y + "px",
            left: preview.x + "px",
          }}
        >
          <Resizable
            size={size}
            onResizeStop={resizeStop}
            minHeight="350px"
            minWidth="350px"
            maxWidth="900px"
            maxHeight="900px"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
              background: "white",
              border: "1px solid #ddd",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.5)",
              zIndex: 1000,
              overflow: "hidden",
            }}
          >
            <div style={{ width: "100%", height: "100%" }}>
              <StyledDivRowSpace
                onMouseDown={enableDragging}
                onMouseUp={disableDragging}
                onMouseMove={onMouseMove}
              >
                <Tooltip title="Open in New Window" placement="top">
                  <IconButton onClick={handlePortalOpen}>
                    <OpenInNewIcon style={{ color: "white" }} />
                  </IconButton>
                </Tooltip>

                <StyledDivCol>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "bold", color: "white" }}
                  >
                    2D PREVIEW
                  </Typography>
                  <Typography
                    variant="caption"
                    style={{ color: "white", fontSize: 10 }}
                  >
                    AS VIEWED FROM EXTERIOR
                  </Typography>
                </StyledDivCol>

                <IconButton onClick={handleClose}>
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
              </StyledDivRowSpace>

              <StyledDivCol>
                <Typography variant="caption">
                  All proportions and finishes are best approximations
                </Typography>
              </StyledDivCol>
              <CanvasBox
                canvasWidth={size.width}
                canvasHeight={size.height}
                setSize={setSize}
              />
            </div>
          </Resizable>
        </StyledDivFixed>
      ) : !open && showPortal ? (
        <Portal handleClose={handlePortalClose}>
          <CanvasBox
            canvasWidth={size.width}
            canvasHeight={size.height}
            setSize={setSize}
          />
        </Portal>
      ) : null}
    </>
  );
};

export default Preview;
