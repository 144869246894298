import { roundTo4 } from "../../quotePDF/CutSheets/StoryPole";

export function openingHeightFormula(
    doorType: string,
    stiles: string,
    panelHeight: number,
    pivotDetail?: string,
    sliderPanelSqft?: number
  ) {
    let openingHeight: number = 0;
    let openingHeightDD:  number = 0;
  
    if (pivotDetail) {
      const fritsSelfClose = pivotDetail === "Fritsjurgens Self Closing";
      const fritsFreeSwing = pivotDetail === "Fritsjurgens Free Swing";
  
      if (stiles.includes("Slimline")) {
        openingHeight = fritsSelfClose
          ? roundTo4(panelHeight - 6.9375)
          : fritsFreeSwing
          ? roundTo4(panelHeight - 4.6875)
          : roundTo4(panelHeight - 3.75);
  
        openingHeightDD = roundTo4(panelHeight - 3.75);
      } else if (stiles.includes("Euro")) {
        openingHeight = fritsSelfClose
          ? roundTo4(panelHeight - 6.9375)
          : roundTo4(panelHeight - 5.625);
      } else if (stiles === 'Bottom rail 7"') {
        openingHeight = roundTo4(panelHeight - 10.5);
      } else if (stiles === 'Bottom rail 4-3/4"') {
        openingHeight = roundTo4(panelHeight - 8.25);
      }
  
      return { openingHeight, openingHeightDD };
    }
  
    if (stiles.includes("Slimline")) {
      openingHeight =
        doorType === "Slider"
          ? roundTo4(panelHeight - 4.6875)
          : roundTo4(panelHeight - 3.75);
      openingHeightDD = roundTo4(panelHeight - 3.75);
    } else if (stiles.includes("Euro")) {
      let sub = 5.625;
      if(doorType === "Slider" && sliderPanelSqft){
        const sqFt = Number(sliderPanelSqft)
        if(sqFt > 50){
          sub = 6.9375
        }
      }
      openingHeight = roundTo4(panelHeight - sub);
    } else if (stiles === 'Bottom rail 7"') {
      openingHeight = roundTo4(panelHeight - 10.5);
    } else if (stiles === 'Bottom rail 4-3/4"') {
      openingHeight = roundTo4(panelHeight - 8.25);
    }
  
    return { openingHeight, openingHeightDD };
  }