import { create } from "zustand";
import { persist } from "zustand/middleware";
import { savedQuote } from "../util/quoteCartStorage";
import { initialExpand, InitialExpandItm } from "../initialValues/index";
import resetModalTextHandler from "../util/resetModalTextHandler";
import { UnitObj } from "../util/quoteCartStorage";

interface CreateStore {
  quote: UnitObj
  widthErr: string
  widthErrCornerRight: string
  heightErr: string
  jambErr: string
  configErr: string
  swingWidthErr: string
  sideliteWidthErr: string
  primaryDoorErr: string
  pivotPointErr?: string
  resetModalText: any
  isEdit: boolean
  getStore: () => UnitObj
  handleError: (errKey: string, msg: string) => void
  addToQuote: (updateObj: Partial<UnitObj>, ignoreKey?: string) => void
  clearModalText: () => void
  resetQuote: () => void
  setStoreFromEdit: (unitInfo: UnitObj) => void
  checkForEmptyVals: () => boolean
}

export const useStore = create<CreateStore>()(
  persist(
    (set, get) => ({
      quote: { ...savedQuote },
      widthErr: "",
      widthErrCornerRight: "",
      heightErr: "",
      jambErr: "",
      configErr: "",
      swingWidthErr: "",
      sideliteWidthErr: "",
      primaryDoorErr: "",
      resetModalText: [],
      isEdit: false,
      getStore: () => {
        const storeQuote = get().quote;
        return storeQuote;
      },
      handleError: (errKey: string, msg: string) =>
        set((state: any) => ({
          ...state,
          [errKey]: msg,
        })),
      addToQuote: (updateObj: Partial<UnitObj>, ignoreKey?: string) => {
        const prevQuote = get().quote;

        const newQuote = { ...prevQuote, ...updateObj };

        let modalText: string[];

        if(ignoreKey){
          modalText = resetModalTextHandler(prevQuote, newQuote, ignoreKey);
        }else{
          modalText = resetModalTextHandler(prevQuote, newQuote);
        }

        if (modalText.length) {
          set((state: any) => ({
            ...state,
            resetModalText: modalText,
            quote: newQuote,
          }));
        } else {
          set((state: any) => ({
            ...state,
            quote: newQuote,
          }));
        }
      },
      clearModalText: () =>
        set((state: any) => ({
          ...state,
          resetModalText: [],
        })),
      resetQuote: () =>
        set((state: any) => ({
          ...state,
          isEdit: false,
          quote: { ...savedQuote },
          widthErr: "",
          widthErrCornerRight: "",
          heightErr: "",
          jambErr: "",
          configErr: "",
          swingWidthErr: "",
          sideliteWidthErr: "",
          primaryDoorErr: "",
          resetModalText: [],
        })),
      setStoreFromEdit: (unitInfo: UnitObj) =>
        set((state: any) => ({
          ...state,
          isEdit: true,
          resetModalText: ["None"],
          quote: unitInfo,
        })),
      checkForEmptyVals: () => {
        const storeReset = get().quote;

        var hasVals = false;

        for (const key in storeReset) {
          if (
            !hasVals &&
            key !== "doorType" &&
            key !== "quantity" &&
            key !== "glassType" &&
            key !== "breatherTubes" &&
            key !== "title24Comp" &&
            key !== "sideLite" &&
            key !== "sillStyle" &&
            key !== "addToQuote" &&
            key !== "resetQuote" &&
            key !== "checkForEmptyVals"
          ) {
            if (key in storeReset) hasVals = true;
          }
        }

        return hasVals;
      },
    }),
    {
      name: "store",
      partialize: (state: any) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) =>
              ![
                "checkForEmptyVals",
                "setStoreFromEdit",
                "addToQuote",
                "resetQuote",
                "clearModalText",
                "handleError",
                "getStore",
              ].includes(key)
          )
        ),
    }
  )
);


interface AccordionState {
  expanded: InitialExpandItm[]; 
  setExpandedFromEdit: (unitExpanded: InitialExpandItm[]) => void;
  handleAccordClick: (name: string) => void;
  handleMinimize: () => void;
}


export const useAccordion = create<AccordionState>()(
  persist(
    (set: (state: any) => any) => ({
      expanded: JSON.parse(JSON.stringify(initialExpand)),
      setExpandedFromEdit: (unitExpanded: InitialExpandItm[]) =>
        set((state: any) => ({
          ...state,
          expanded: unitExpanded[33]
            ? unitExpanded
            : [
                ...unitExpanded,
                { name: "kerf", expanded: true, require: false },
              ],
        })),
      handleAccordClick: (name: string) =>
        set((state: any) => ({
          ...state,
          expanded: state.expanded.map((accord: InitialExpandItm) =>
            accord.name === name
              ? { ...accord, expanded: !accord.expanded }
              : { ...accord }
          ),
        })),
      handleMinimize: () =>
        set((state: any) => ({
          ...state,
          expanded: JSON.parse(JSON.stringify(initialExpand)),
        })),
    }),
    {
      name: "accordionControl",
      partialize: (state: any) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) =>
              ![
                "handleMinimize",
                "handleAccordClick",
                "setExpandedFromEdit",
              ].includes(key)
          )
        ),
    }
  )
);
