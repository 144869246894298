import React, { FC } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ExportBtn from "./Export/ExportBtn";
import { FileCopy as FileCopyIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
  color: "#4b4b4b",
}));

const StyledDivRowBtw = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  position: "relative",
});

const StyledDivRowEnd = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "100%",
  padding: 10,
});

interface TableToolbarProps {
  showTab: string
  numSelected: number
  unCheckAll: () => void
  searchTerm: string
  searchTermFilter: string
  isSearch: boolean
  handleOpenModalArchive: () => void
  status: string
  showArchive?: string
  openDuplicateModal: () => void
  openSaberisModal?: () => void
}

function isSaberisAllowed(numSelected: number, email: string) {
  if(numSelected !== 1) return false;
  const allowedAccounts = ["adam@agmillworks.com", "dgregory@goldenstatewdd.com"];

  if (allowedAccounts.includes(email)) {
    return true;
  }
  return false;
}

const TableToolbarDealer: FC<TableToolbarProps> = ({
  showTab,
  numSelected,
  unCheckAll,
  searchTerm,
  searchTermFilter,
  isSearch,
  handleOpenModalArchive,
  status,
  showArchive,
  openDuplicateModal,
  openSaberisModal
}) => {
  const email = JSON.parse(sessionStorage.getItem("email") || "");

  const showSaberis = isSaberisAllowed(numSelected, email);
  
  return (
      <StyledToolbar style={{ backgroundColor: numSelected > 0 ? "#57d5f2" : "" }}>
        <StyledDivRowBtw>
          {numSelected > 0 ? (
            <>
              <Typography
                style={{ flex: "1 1 100%" }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {numSelected} selected
              </Typography>
              <button
                onClick={unCheckAll}
                style={{ position: "absolute", left: 100, borderRadius: 5 }}
              >
                Un-Check All
              </button>

              <StyledDivRowEnd>
                {numSelected === 1 &&
                status === "Quote" &&
                showTab === "Quotes" ? (
                  <div style={{ padding: 5 }}>
                    <Button
                      style={{ color: "white", fontSize: 11 }}
                      onClick={openDuplicateModal}
                      size="small"
                      color="primary"
                      variant="contained"
                    >
                      <FileCopyIcon style={{ fontSize: 14 }} />
                      Duplicate
                    </Button>
                  </div>
                ) : null}

                {showSaberis && (
                  <div style={{ padding: 5 }}>
                    <Button
                      variant="contained"
                      size="small"
                      style={{ color: "white", backgroundColor: "#2b3f82", fontSize: 11 }}
                      onClick={openSaberisModal}
                    >
                      Saberis Connect
                    </Button>
                  </div>
                )}

                <div style={{ padding: 5 }}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{ color: "white", fontSize: 11 }}
                    onClick={handleOpenModalArchive}
                  >
                    {status === "Archived" ? "Move to Quotes" : "Archive"}
                  </Button>
                </div>
              </StyledDivRowEnd>
            </>
          ) : (
            <div />
          )}

          <div style={{ padding: 5 }}>
            <ExportBtn
              showTab={showTab}
              searchTerm={searchTerm}
              searchTermFilter={searchTermFilter}
              isSearch={isSearch}
              showArchive={showArchive}
            />
          </div>
        </StyledDivRowBtw>
      </StyledToolbar>
  );
};

export default TableToolbarDealer;
